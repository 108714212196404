import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 2, 4, 5) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'CHEESE',
    lpAddresses: {
      97: '0x7AEd60fb8d0884BEDcb186Df495F760c492393b5',
      56: '0xf1c4BdAb1002f823493C41342FfBe456cf814570',
    },
    token: serializedTokens.syrup,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 2,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '0x4e622bB8c46910Ec7652ED2485bed8c1f9F54add',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 4,
    lpSymbol: 'CHEESE-BNB LP',
    lpAddresses: {
      97: '0x2a79dd6572aba81ea2f26c412621fe735cc73114',
      56: '0x94A376A68E3DcF2C48CB1E3b64C934894662F767',
    },
    token: serializedTokens.cheese,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 5,
    lpSymbol: 'CHEESE-BUSD LP',
    lpAddresses: {
      97: '0x34d8f23b403a0400af3356b34dfccfd71f430e40',
      56: '0x5033542ef355A58cEDfB42f91025bD3a1d81655B',
    },
    token: serializedTokens.cheese,
    quoteToken: serializedTokens.busd,
  },
 
]

export default farms
